// HELPER: check if element exists
function isElement(element) {
  return typeof element != "undefined" && element != null;
}

$(document).ready(function () {
  // NAV BAR
  // Add smooth scrolling to links with a class of .smooth
  $(".smooth").on("click", function (e) {
    e.preventDefault();
    var href = $(this).attr("href");

    $("html, body").animate(
      {
        scrollTop: $(href).offset().top - 30,
      },
      "slow"
    );
  });

  // Close responsive mobile nav when a nav-link is clicked
  $("a").click(function () {
    $(".navbar-collapse").collapse("hide");
  });

  // OPEN STREET MAP
  // with leaflet

  if (isElement(document.getElementById("mapid"))) {
    var map = L.map("mapid", {
      scrollWheelZoom: false,
      id: "mapbox.streets",
    }).setView([51.302799, 9.8051523], 10);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      accessToken:
        "pk.eyJ1IjoiamFjdWJjbyIsImEiOiJjamsxYXpyNGUwMG53M29vM3U0cWJrZ256In0.ctFgGh128ELXksKhQep6VQ",
    }).addTo(map);

    L.marker([51.302799, 9.8051523])
      .addTo(map)
      .bindPopup("<b>Lama-Wandern</b><br />in Oberroßbach")
      .openPopup();
  }

  // Parallax on iOS
  // detect ios
  var isIOS =
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
    !window.MSStream;

  var touren = document.getElementById("touren");
  if (isIOS) {
    function parallaxEffect(parallaxName, osMod) {
      window.addEventListener("scroll", function () {
        var offset = window.pageYOffset;
        var parallaxOffset = document.getElementById("parallax").offset;
        console.log(offset);

        parallaxName.style.backgroundPositionY = (offset - 2000) * 0.15 + "px";
      });
    }

    var parallaxClasses = document.querySelectorAll(".parallax");

    for (i = 0; i < parallaxClasses.length; i++) {
      parallaxClasses[i].className += " parallax--ios";
    }

    var parallax = document.getElementById("parallax");
    parallax.style.backgroundAttachment = "inherit";
    parallaxEffect(parallax, 10);
    cbr();
  }

  function cbr() {
    console.log(touren.getBoundingClientRect());
    console.log(parallax.getBoundingClientRect());
  }
  /*

  ///////////////////
 // Contact Form ///
///////////////////

// Initilize jsEnabled
// Use in contact.php to prevent no-js fallback behavior
var jsEnabled = true;

// Initilize Global DOMStrings Object to access DOM
var DOMStrings = {
  'form': document.getElementById('contact-form'),
  'formName': document.getElementById('name'),
  'formEmail': document.getElementById('email'),
  'formMessage': document.getElementById('message'),
  'formInfo': document.getElementById('form__info')
}

// Use helper to capitalize strings
function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Remove form action and method
// No need: js will handle form submit not PHP!
function removeFormAction() {
  DOMStrings.form.removeAttribute('action');
  DOMStrings.form.removeAttribute('method');
}
removeFormAction();

// Get form values
function getFormValues(e) {
  var formValues = {
    'name': DOMStrings.formName.value,
    'email': DOMStrings.formEmail.value,
    'message': DOMStrings.formMessage.value,
    'missingData': [],
  }
  // Check if all required data was submitted
  for (key in formValues) {
    if(formValues[key] === '') {
      formValues.missingData.push(key);
    }    
  }
  e.preventDefault();
  return formValues;
}

// Submit form
function submitForm(formValues, jsEnabled = true) {
  var name = formValues.name;
  var email = formValues.email;
  var message = formValues.message;
  var jsEnabled = jsEnabled;

  if(name && email && message) {

    $.ajax({
      type: "POST",
      url: "contact.php",
      data: "name=" + name + "&email=" + email + "&message=" + message +"&jsEnabled=" + jsEnabled,
      success: function(messageFromContactPHP){
        if (messageFromContactPHP == 'message sent successfully'){
            formSuccess(formValues);
            // console.log(text);
        } else {
          formError(formValues);
          // console.log('Something went wrong.');
        }
      } 
    });
  } else {
    formError(formValues);
    // console.log('Form could not be sent');
  }
}

// Generate info messages
function getInfoMessages(status, formValues){
  var markup;
  if (status) {
    markup = '\
      <div class="alert alert-success">\
        <p><strong>Vielen Dank für Ihre Nachricht.</strong></p>\
        <p><strong>Name:</strong> ' + formValues.name + '</p>\
        <p><strong>Email:</strong> ' + formValues.email + '</p>\
        <p><strong>Message:</strong> ' + formValues.message + '</p>\
      </div>'
  } else {
      var list = 'Something went wrong.';
      formValues.missingData.forEach(function(key) {
        list += '<p>' + capitalize(key) + ' is required.</p>';
      })
      markup = '\
        <div class="alert alert-danger">'
          + list +
        '</div>';
  }
  return markup;
}

function formSuccess(formValues) {
  var markup;
  var form = DOMStrings.form;
  var formInfo = DOMStrings.formInfo;
  markup = getInfoMessages(1, formValues);
  
  form.innerHTML = '';
  formInfo.innerHTML = markup;
}

function formError(formValues){
  var markup;
  var formInfo = DOMStrings.formInfo;
  markup = getInfoMessages(0, formValues);
    
  formInfo.innerHTML = markup;
}

function formController(e) {
  var formValues = getFormValues(e);
  submitForm(formValues, jsEnabled);
}

// Listen for form submit
DOMStrings.form.addEventListener('submit', formController);
*/
});
